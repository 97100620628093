import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (event: any) => {
    const newLang = event.target.value;
    i18n.changeLanguage(newLang); // Update i18next language
    localStorage.setItem('language', newLang);

  };
  useEffect(() => {
    // Check if the current language is one of the allowed languages ('en', 'fr', 'nl')
    // If not, default it to English ('en')
    const currentLang = localStorage.getItem('language') || i18n.language; // To handle regional variants like 'en-US'
    if (!['en', 'fr', 'nl'].includes(currentLang)) {
      i18n.changeLanguage('en');
    } else if (['en', 'fr', 'nl'].includes(currentLang)) {
      i18n.changeLanguage(currentLang);
    }
  }, []);




  return (
    <select
      className="cursor-pointer py-1.5 px-2 border-[2px] border-solid border-transparent hover:border-olivegreen bg-transparent"
      onChange={changeLanguage}
      value={i18n.language}
    >
      <option value="en">EN</option>
      <option value="fr">FR</option>
      <option value="nl">NL</option>
    </select>


  );
}

export default LanguageSwitcher;
