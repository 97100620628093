import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import Slider from 'react-slick';
import Card from "../blog/Card";
import { fetchBlogsByIds } from '../../utils/api/api';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HorizontalBanner: React.FC = () => {
    const [blogs, setBlogs] = useState<any[]>([]);
    const ids = [108, 111, 222, 112, 171, 67, 232, 235, 180];

    useEffect(() => {
        fetchBlogsByIds({ ids })
            .then(data => setBlogs(data))
            .catch(error => console.error('Error fetching blogs:', error));
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="max-w-6xl mx-auto pb-16">
            <Slider {...settings}>
                {blogs.map((blog, index) => (
                    <div key={index} className="px-4 py-5 !flex !justify-center">
                        <Link to={`/${blog.author.username}/${blog.id}`}>
                            <Card blog={blog} />
                        </Link>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default HorizontalBanner;

