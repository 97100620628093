import { FunctionComponent, useMemo, type CSSProperties } from "react";

type SlowTravLogoType = {
  dimension?: string;
  dimensionText?: string;

  /** Style props */
  SlowTravLogoTextDecoration?: CSSProperties["textDecoration"];
};

const SlowTravLogo: FunctionComponent<SlowTravLogoType> = ({
  dimension,
  dimensionText,
  SlowTravLogoTextDecoration,
}) => {
  const SlowTravLogoStyle: CSSProperties = useMemo(() => {
    return {
      textDecoration: SlowTravLogoTextDecoration,
    };
  }, [SlowTravLogoTextDecoration]);

  return (
    <div
      className="flex flex-row items-center justify-start text-left text-2xs text-studio-darkmode-popuplabels-5e6a86 font-text-small"
      style={SlowTravLogoStyle}
    >
      <img
        className="relative rounded-lg h-[45px] overflow-hidden shrink-0"
        alt=""
        src={dimension}
      />
      {/* <div className="flex flex-col items-start justify-center gap-[4px] font-roboto text-5xl font-semibold antialiased text-secondary-200">
        slostr
      </div> */}
    </div>
  );
};

export default SlowTravLogo;
