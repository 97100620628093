import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
}

const AppPopup = () => {
    const { t, i18n } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isMobileDevice()) {
            setIsVisible(true);
        }
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 16,
                left: 16,
                right: 16,
                bgcolor: 'background.paper',
                boxShadow: 3,
                borderRadius: 2,
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mr: 2, fontSize: '13px' }}>
                    {t('slostr-first-version')}
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <a href="https://apps.apple.com/be/app/slostr/id6590637281" target="_blank" rel="noopener noreferrer">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/App_Store_%28iOS%29.svg/512px-App_Store_%28iOS%29.svg.png" alt="Download on the App Store" style={{ maxWidth: '40px' }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.slostr.Slostr&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                        <img src="https://cdn-icons-png.flaticon.com/512/732/732208.png" alt="Get it on Google Play" style={{ maxWidth: '40px', backgroundColor: 'transparent' }} />
                    </a>
                </Box>
            </Box>
            <IconButton onClick={handleClose} color="inherit">
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export default AppPopup;
