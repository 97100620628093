import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';


interface ErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  errorMessage?: string;
  errorData?: Record<string, any> ;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, onClose, errorMessage, errorData }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose()
  }

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  return (
    <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  open={isOpen}
  autoHideDuration={6000}
  onClose={handleClose}
  action={action}
>
<Alert
    onClose={handleClose}
    severity="error"
    variant="filled"
    sx={{ width: '100%' }}
  >
   {errorData.detail || errorMessage}
  </Alert>
  </Snackbar>
  )

  // return ReactDOM.createPortal(
  //   <div className="shadow-3xl rounded-[15px] top-[10%] fixed left-[10%] bg-white z-[1000] w-[80%] h-[80%]">
  //         <div className="flex-row justify-center items-center md:flex-col grid md:grid-cols-2">
  //     <div className="w-full flex flex-col items-center justify-center py-2 box-border md:w-full md:py-2 md:px-0">
  //       <Chip label={errorMessage} color="error" variant="outlined" />
  //       <h1 className="font-roboto text-md md:text-xl font-normal">Oops ! Something went wrong</h1>

  //       <div className="p-3 overflow-hidden flex flex-col items-start justify-center box-border">
  //           <h3 className="font-roboto font-[400] text-md md:text-lg">The error was registered and we'll fix it as soon as possible. Thank you for your patience 😅</h3>
  //           {/* {errorData && typeof errorData === 'object' && (
  //           Object.entries(errorData).map(([key, value]) => (
  //             <p key={key} className="font-roboto font-light px-3 md:text-xs"> {key}: {typeof value === 'object' ? JSON.stringify(value) : value}</p>
  //           ))
  //         )} */}
  //           <h3 className="font-roboto font-light text-md md:text-lg">If you were creating a trip, please go back on the trip and save your content somewhere to not lose it. The error will be fixed as soon as possible and your trip can then be added at a later stage 😄 Thank you! </h3>
  //           <Button variant="contained" onClick={onClose}>Close</Button>
  //       </div>
  //     </div>
  //     <div className='w-full flex justify-center items-center'>
  //     <img
  //         className="max-h-[60vh] object-cover w-[150px] md:w-1/2 md:m-10 lg:flex-1 rounded-[20px] shadow-xl"
  //         loading="lazy"
  //         alt=""
  //         src="/broken.png"
  //       />
  //     </div>

  //       </div>
  //   </div>,
  //   document.body
  // );
};

export default ErrorModal;
