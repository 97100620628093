import { createTheme, ThemeProvider } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
          main: '#8ABFA3',
          contrastText: '#fff',
        },
        warning: {
          main: '#97bc62',
          contrastText: '#fff',
        },
        error: {
          main: '#d34c4c',
          contrastText: '#fff',
        },
        secondary: {
          main: '#F88379',
          contrastText: '#fff',
        },
        info: {
          main: '#2C5F2D',
          contrastText: '#fff',
        },
        success: {
          main: '#FFA500',
          contrastText: '#fff',
        },
      },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            // Define your placeholder styles here
            fontStyle: 'italic',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '1rem',
            fontWeight: '200',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&::placeholder': {
            // Repeat the same styles if necessary
            fontStyle: 'italic',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '1rem',
            fontWeight: '200',
          },
        },
      },
    },
    // You can add other component overrides here
  },
});
