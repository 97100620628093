import { FunctionComponent, useState, useEffect } from "react";



const LoadingPage: FunctionComponent = () => {

  const [isActive, setIsActive] = useState(false);



  useEffect(() => {
    // Add the active class after a delay to trigger the animation
    const timer = setTimeout(() => {
      setIsActive(true);
    }, 10); // Delay to ensure the component is fully mounted

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, []);




  return (
      <div className="w-full relative overflow-hidden gap-[30px]"
      style={{backgroundImage: 'linear-gradient(120deg, #fffff 0%, #fffff 100%)'}}>

        {/* Hero */}
        <div className="relative w-full h-screen flex items-center justify-center">

            <div className="w-full max-w-[800px]">
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 600.000000 283.000000" preserveAspectRatio="xMidYMid meet"
        className={isActive ? 'active' : ''}
        >

<g transform="translate(0.000000,283.000000) scale(0.100000,-0.100000)" fill="#97bc62" stroke="none">
<path d="M1500 1665 l0 -725 85 0 85 0 0 725 0 725 -85 0 -85 0 0 -725z" className="svg-elem-1"></path>
<path d="M4467 2204 c-4 -4 -7 -61 -7 -126 l0 -118 -90 0 -90 0 0 -65 0 -65
89 0 89 0 5 -357 c5 -393 8 -417 63 -482 49 -56 103 -75 207 -69 45 2 88 8 95
12 7 4 12 32 12 73 l0 65 -52 -6 c-62 -8 -115 14 -135 54 -9 19 -13 114 -13
368 l0 342 95 0 95 0 0 65 0 65 -95 0 -94 0 -3 123 -3 122 -80 3 c-45 1 -84 0
-88 -4z" className="svg-elem-2"></path>
<path d="M2425 2184 c-379 -61 -664 -380 -669 -749 -1 -131 21 -220 90 -361
89 -180 265 -396 472 -581 141 -125 198 -146 280 -104 94 48 358 316 484 493
235 327 270 636 104 913 -109 182 -284 317 -481 370 -61 16 -226 27 -280 19z
m281 -102 c216 -67 402 -247 471 -456 37 -111 38 -274 4 -386 -53 -175 -194
-382 -396 -585 -215 -216 -250 -235 -336 -174 -68 48 -327 314 -396 407 -113
149 -181 284 -204 403 -17 87 -7 270 20 344 87 243 301 422 561 469 51 9 217
-4 276 -22z" className="svg-elem-3"></path>
<path d="M2395 2015 c-245 -54 -433 -254 -472 -502 -14 -88 -14 -104 0 -177
42 -209 190 -376 397 -447 89 -30 260 -38 350 -15 114 28 221 89 297 170 80
84 114 143 142 246 36 128 21 267 -43 400 -41 85 -175 223 -263 268 -125 65
-275 86 -408 57z m25 -200 c19 -9 51 -33 71 -51 l35 -34 21 29 c57 76 191 103
288 57 75 -35 156 -128 180 -206 7 -23 6 -23 -30 9 -81 71 -172 89 -251 50
-73 -35 -104 -112 -70 -177 23 -45 59 -71 144 -106 105 -44 136 -67 162 -121
30 -63 22 -85 -63 -170 -83 -82 -164 -127 -272 -150 -88 -18 -132 -18 -220 0
-139 29 -299 136 -349 233 -16 31 -16 35 2 75 27 59 68 93 153 128 131 53 169
92 169 172 0 54 -27 92 -84 118 -81 37 -169 19 -238 -49 -32 -30 -38 -33 -38
-17 0 61 114 190 193 219 53 20 150 15 197 -9z m-130 -245 c33 -33 26 -76 -17
-99 -43 -22 -101 24 -85 69 17 50 67 65 102 30z m550 0 c36 -36 21 -89 -30
-105 -41 -13 -86 34 -74 80 12 46 69 60 104 25z" className="svg-elem-4"></path>
<path d="M2483 1485 c-18 -8 -38 -26 -45 -40 -10 -22 -8 -30 8 -52 37 -49 103
-54 144 -13 26 26 25 62 -2 88 -31 28 -67 34 -105 17z" className="svg-elem-5"></path>
<path d="M2317 1343 c-32 -31 46 -94 141 -114 114 -23 267 29 267 91 0 32 -30
38 -58 12 -33 -31 -71 -42 -145 -42 -66 0 -141 24 -156 49 -8 12 -39 15 -49 4z" className="svg-elem-6"></path>
<path d="M728 1961 c-245 -64 -337 -324 -165 -465 56 -46 93 -61 259 -106 205
-56 246 -84 255 -171 12 -118 -142 -191 -312 -148 -63 16 -120 68 -140 129
l-17 50 -85 0 -85 0 7 -47 c19 -126 126 -231 275 -269 91 -23 253 -15 334 16
77 29 169 117 185 175 17 62 13 154 -8 200 -41 88 -151 152 -346 199 -182 45
-235 81 -235 159 0 91 75 147 198 147 116 0 195 -51 217 -142 7 -28 8 -28 91
-28 l84 0 0 36 c0 51 -45 138 -93 180 -57 49 -107 73 -186 90 -86 18 -151 17
-233 -5z" className="svg-elem-7"></path>
<path d="M3657 1964 c-183 -44 -292 -187 -258 -340 25 -112 122 -179 341 -235
203 -52 255 -88 255 -179 0 -90 -66 -140 -196 -148 -136 -8 -232 47 -259 147
l-11 41 -86 0 -86 0 6 -45 c24 -171 191 -285 417 -285 100 1 156 12 225 46 99
50 153 125 162 226 16 171 -80 258 -366 333 -195 52 -231 77 -231 163 0 52 32
99 83 122 53 24 162 26 215 4 52 -21 101 -72 113 -116 l10 -38 84 0 85 0 0 28
c0 86 -76 194 -168 241 -94 48 -225 62 -335 35z" className="svg-elem-8"></path>
<path d="M5372 1965 c-53 -16 -115 -58 -139 -94 l-18 -26 -5 55 -5 55 -82 3
-83 3 0 -511 0 -510 85 0 85 0 0 360 c0 391 0 388 57 441 52 49 92 63 180 63
l83 1 0 81 c0 76 -2 82 -22 88 -36 9 -89 6 -136 -9z" className="svg-elem-9"></path>
</g>
</svg>
  </div>
  </div>
  </div>

    )
};

export default LoadingPage;
