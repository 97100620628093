// ErrorBoundary.js
import React from 'react';
import ErrorContext from './ErrorContext'; // Import the context object

// @ts-ignore
class ErrorBoundary extends React.Component {
  static contextType = ErrorContext;

  componentDidCatch(error: any, errorInfo: any) {
    // Log error details to console
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    // Use context to show error modal

    // Check if showError function is available in the context
      // @ts-ignore
    if (this.context && this.context.showError) {
        // @ts-ignore
      this.context.showError(error.toString(), { errorInfo });
    } else {
      console.error("showError function not available in context");
    }


  }

  render() {
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
