import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";

import { fetchMaintenance } from "../../utils/api/api";

const formatDate = (dateStr: any) => {
  const date = new Date(dateStr);
  return (
    date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) +
    " " +
    date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })
  );
};

const MaintenanceBanner = () => {
  const [maintenanceInfo, setMaintenanceInfo] = useState<any>({
    active: false,
    message: "",
  });

  useEffect(() => {
    const fetchMaintenanceBanner = async () => {
      try {
        const data = await fetchMaintenance();
        if (data.active) {
          setMaintenanceInfo({
            active: true,
            message: data.message,
            start: formatDate(data.start_time),
            end: formatDate(data.end_time)
          });
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
        const customEvent = new CustomEvent("apiError", { detail: error });
        window.dispatchEvent(customEvent);
      }
    };

    fetchMaintenanceBanner();
  }, []);

  if (!maintenanceInfo.active) {
    return null;
  }

  return (
    <div className="z-[999999999] w-full fixed top-[80px] flex items-center justify-center">
      <Alert
        severity="warning"
        onClose={() => {
          setMaintenanceInfo({ active: false });
        }}
      >
                {`Hi! From ${maintenanceInfo.start} to ${maintenanceInfo.end} ${maintenanceInfo.message}`}
      </Alert>
    </div>
  );
};

export default MaintenanceBanner;
