// ErrorContext.js
import React, { createContext, useState, useContext } from "react";
import ErrorModal from './ErrorModal';

const ErrorContext = createContext<any>(null);


export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }: (any)) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorDetails, setErrorDetails] = useState({ message: '', data: null });

  const showError = (message: any, data: any) => {
    setErrorDetails({ message, data });
    setIsErrorModalOpen(true);
  };

  const hideError = () => {
    setIsErrorModalOpen(false);
    setErrorDetails({ message: '', data: null });
  };

  return (
    <ErrorContext.Provider value={{ showError, hideError }}>
      {children}
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={hideError}
        errorMessage={errorDetails.message}
        errorData={errorDetails.data || {}}
      />
    </ErrorContext.Provider>
  );
};

export default ErrorContext;
