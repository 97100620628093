import { FunctionComponent, useMemo, type CSSProperties } from "react";

type SizesmallStyleStrokeShapType = {
  buttonText?: string;
  bordered?: boolean;

  /** Style props */
  fillColor?: CSSProperties["border"];
  buttonColor?: CSSProperties["color"];
};

const SizesmallStyleStrokeShap: FunctionComponent<
  SizesmallStyleStrokeShapType
> = ({ buttonText, fillColor, buttonColor, bordered=false }) => {


  return (
    <button
      className={`cursor-pointer py-1.5  px-1 sm:px-4 bg-[transparent] rounded-md overflow-hidden flex flex-row items-center justify-center hover:text-${buttonColor? buttonColor : 'gray'} border-[2px] border-solid hover:border-primary ${bordered ? 'border-primary' : 'border-transparent'} mq725:w-full`}
    >
      <div
        className={`uppercase font-medium antialiased text-xs sm:text-sm text-center text-slate-800 hover:text-${buttonColor? buttonColor : 'gray'}`}
      >
        {buttonText}
      </div>
    </button>
  );
};

export default SizesmallStyleStrokeShap;
