// AuthenticatedRoute.tsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './/AuthContext';

interface AuthenticatedRouteProps {
    children: React.ReactNode;
  }

  const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children }) => {
    const authContext = useContext(AuthContext);
    const isAuthenticated = authContext?.isAuthenticated;

    return isAuthenticated ? <>{children}</> : <Navigate to="/" replace />;
  };

  export default AuthenticatedRoute;
