// src/contexts/NotificationContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

import { fetchFollowView } from '../utils/api/api';
import { AuthContext } from "./authentification/AuthContext";


const NotificationContext = createContext<any | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
const authContext = useContext(AuthContext);
const { user } = authContext || {};

  const [notificationCount, setNotificationCount] = useState(0);
  const [community, setCommunity] = useState<any[]>([]);

  useEffect(() => {
    // Fetch follow request data and update notification count
    const fetchFollowRequests = async () => {
      try {
        const data = await fetchFollowView(); // Your API call to fetch follow requests
        // Assuming 'data' is an array of follow requests
        setCommunity(data)
        const unapprovedRequests = data.filter((request: any) => !(request.request_seen))

        const unapprovedRequests_ = unapprovedRequests.filter((request: any) => !(request.follower?.id === user?.id))
        setNotificationCount(unapprovedRequests_.length);
      } catch (error) {
        console.error("Error fetching follow requests:", error);
      }
    };
    if (user) {
      fetchFollowRequests();
    }

  }, [user]);

  return (
    <NotificationContext.Provider value={{ notificationCount, setNotificationCount, community, setCommunity }}>
      {children}
    </NotificationContext.Provider>
  );
};
