import termsOfUseHtml from '!!raw-loader!./TermsOfUse.html';
import PrivacyPolicyHtml from '!!raw-loader!./PrivacyPolicy.html';
import CookiesHtml from '!!raw-loader!./Cookies.html';

import Footer from "../../components/main/Footer";
import Navbar from "../../components/main/Navbar";


export const TermsOfUse = () => (
    <div className={`w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px]`}>
      <Navbar />
<div className="pt-[80px] px-10" dangerouslySetInnerHTML={{ __html: termsOfUseHtml }} />
        <Footer />
        </div>
)

export const PrivacyPolicy = () => (
    <div className={`w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px]`}>
    <Navbar />
    <div className="pt-[80px] px-10" dangerouslySetInnerHTML={{ __html: PrivacyPolicyHtml }} />
    <Footer />
        </div>
    )

export const CookiesPolicy = () => (
    <div className={`w-full relative bg-primary-contrast overflow-hidden flex flex-col items-center justify-start gap-[30px]`}>
    <Navbar />
    <div className="pt-[80px] px-10" dangerouslySetInnerHTML={{ __html: CookiesHtml }} />
    <Footer />
        </div>
    )
